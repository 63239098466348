import React, { Component } from 'react';
import './App.css';
import { Layout, Header, Content, Navigation, Drawer, Button, Textfield, Snackbar } from 'react-mdl';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Scanner from './camera'
import HomePage from './home'
import logo from './logo.jpeg';
import { version } from 'punycode';
//Icons
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Home from "@material-ui/icons/Home";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Info from "@material-ui/icons/Info";
import Gavel from "@material-ui/icons/Gavel";
import LiveHelp from "@material-ui/icons/LiveHelp";
import androidPDF from './AndroidHelp.pdf';
import iOSPDF from './iOSHelp.pdf';
import Popup from "reactjs-popup";

export const apiEndPoint = "https://pwaapi.truckpartsinventory.com"


//Close the Side-Bar
function closeSider() {
  document.querySelector('.mdl-layout').MaterialLayout.toggleDrawer();
};


export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      token: "",
      isLogedIn: false,
      open: false,
      isSnackbarActive: false,
      timeout: 0
    };
    this.handleShowSnackbar = this.handleShowSnackbar.bind(this);
    this.handleTimeoutSnackbar = this.handleTimeoutSnackbar.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.login = this.login.bind(this);
    this.helpOpener = this.helpOpener.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  //Handle Input Username
  handleUsername(evt) {
    this.setState({ username: evt.target.value });
  }

  //Handle Input Password
  handlePassword(evt) {
    this.setState({ password: evt.target.value });
  }

  handleShowSnackbar() {
    this.setState({ isSnackbarActive: true, timeout: 6000 });
  }
  handleTimeoutSnackbar() {
    this.setState({ isSnackbarActive: false });
  }


  //on Page Load
  componentDidMount() {

    //If Offline || online
    if (!navigator.onLine || navigator.onLine) {
      //If already LoggedIn
      if (localStorage.getItem("LogInDetails") !== null) {
        this.setState({
          token: JSON.parse(localStorage.getItem("LogInDetails")),
          isLogedIn: true
        });

      }
      // If did not LoggedIn
      else if (localStorage.getItem("LogInDetails") === null) {
        this.setState({
          token: "",
          isLogedIn: false
        });
        if (!navigator.onLine) {
          alert("You Have to go Online.. In order to Log-In Into Application");
        }
      }
    }
  }

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }

  helpOpener = (e) => {
    if (e === 'android') {
      window.open(androidPDF);
    } else if (e === 'iOS') {
      window.open(iOSPDF);
    }
  }

  //Login Function
  login = (event) => {
    event.preventDefault();
    if (!navigator.onLine) {
      alert("You Have to go Online.. In order to Log-In Into Application");
    }
    else {
      if (this.state.username === "") {
        alert("Please Type your Username")
      }
      else if (this.state.password === "") {
        alert("Please type your password")
      }
      else if (this.state.username !== "" && this.state.password !== "") {

        var details = {
          'Username': this.state.username,
          'Password': this.state.password,
          'grant_type': 'password'
        };

        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }

        formBody = formBody.join("&");
        fetch(`${apiEndPoint}/api/Account/Post`, {
          method: 'Post',
          mode: 'cors',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          },
          body: formBody
        })
          .then(response => {
            if (response.status >= 400) {
              console.log("Bad Response");
            }
            return response.json();
          })
          .then(response => {
            this.setState({
              token: response.Token,
              isLogedIn: true
            });
            localStorage.setItem("LogInDetails", JSON.stringify(response.Token));
            this.props.history('/home');
            this.handleShowSnackbar();
          }).catch((error) => {
            this.handleShowSnackbar();
          });
      }
    }
  }
  //LogOut
  logOut = (event) => {
    this.setState({
      token: ""
    });
    localStorage.removeItem("LogInDetails");
    localStorage.removeItem("parts");
    window.location.replace("/");
  };
  versionAlert() {
    alert("© Truck Part Solutions " + new Date().getFullYear() + " Version: " + version);
  }



  render() {
    if (this.state.isLogedIn === true) {
      const { isSnackbarActive } = this.state;
      return (
        <Router>
          <div className="App">
            <Layout fixedHeader >
              <Header title={<Link to="/home"><img alt="TPI" src={logo} style={{ height: "40px" }}></img></Link>} style={{ background: '#333' }}>
                <i style={{ color: "#fff" }}><PhotoCamera /></i> <Link to="/camera" style={{ color: "#fff", textDecoration: "none", marginLeft: "5px" }}> Scan</Link>
                <Navigation className='hide-on-sm'>
                </Navigation>
              </Header>
              <Drawer onClick={closeSider} title={<img alt="TPI" src={logo} style={{ height: "40px" }}></img>} >
                <div>
                  <Navigation>
                    <Link to={{ pathname: "/home", loginDetails: this.state.token }} style={{ color: "#333", width: "100%", position: 'absolute', textAlign: "left" }} ><i><Home /></i> Home</Link>
                    <Link to="/" onClick={this.logOut} style={{ color: "#333", position: 'absolute', textAlign: "left", width: "100%", top: 120 }}><i><AccountCircle /></i> Log Out</Link>
                    <Link to="/home" onClick={this.versionAlert} style={{ color: "#333", position: 'absolute', textAlign: "left", width: "100%", bottom: 40 }} ><i><Info /></i> About</Link>
                    <a style={{ color: "#333", position: 'absolute', textAlign: "left", width: "100%", bottom: 0 }} href={apiEndPoint} rel="noopener noreferrer" target="_blank"><i ><Gavel /></i> Terms of Service</a>
                  </Navigation>
                </div>
              </Drawer>
              <Content>
                <Switch>
                  <Route exact path="/" component={HomePage} />
                  <Route path="/home" component={HomePage} />
                  <Route path="/camera" component={Scanner} />
                </Switch>
              </Content>
            </Layout>
            <Snackbar active={isSnackbarActive} onTimeout={this.handleTimeoutSnackbar}>Welcome</Snackbar>
          </div>
        </Router>
      );
    }
    else {
      const { isSnackbarActive } = this.state;
      return (
        <div className="App">
          <Layout fixedHeader>
            <Header>

            </Header>
            <Content>
              <div style={{ marginTop: "40px" }} >
                <img src={logo} alt="TPI"></img><br /><br />
                <Textfield onChange={this.handleUsername} label="Username..." style={{ width: '240px' }} floatingLabel /><br />
                <Textfield type="password" onChange={this.handlePassword} label="Password..." style={{ width: '240px' }} floatingLabel /><br /><br />
                <Button style={{ borderColor: 'gray', borderWidth: 2, borderRadius: 2, color: "#e82c1d", background: "#333", width: '120px', height: '45px' }}
                  onClick={this.login}>Log In</Button><br /><br /><br />
                <Button raised style={{ justifyContent: 'flex-end', marginBottom: 36, color: "#333", fontSize: '10px', textAlign: "center", width: '110px' }}
                  onClick={this.openModal} >Need Help<i style={{ fontSize: '12px' }}><LiveHelp /></i></Button>
              </div>
              <div>
                <Popup open={this.state.open}
                  closeOnDocumentClick
                  onClose={this.closeModal}>
                  <div>
                    <p>Select Your Device</p>

                    <Button raised type='button' onClick={() => {
                      let e = 'iOS';
                      this.helpOpener(e);
                    }}>iOS
                    </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;

                    <Button raised type='button' onClick={() => {
                      let e = 'android';
                      this.helpOpener(e);
                    }} >Android
                    </Button>
                  </div>
                </Popup>

              </div>
            </Content>
            <p>© Copyright {new Date().getFullYear()}.</p>
            <p>All rights reserved. Powered by <a style={{ color: "#e82c1d" }} href="https://truckpartsinventory.com" rel="noopener noreferrer" target="_blank">TruckPartsInventory.com</a></p>
          </Layout>
          <Snackbar active={isSnackbarActive} onTimeout={this.handleTimeoutSnackbar}>Wrong Username or Password</Snackbar>
        </div>
      );
    }
  }
}