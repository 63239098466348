export default function registerServiceWorker() {
  //Check Service Worker Available.
  if ("serviceWorker" in navigator) {
      
        navigator.serviceWorker
        .register(`${process.env.PUBLIC_URL}\sw.js`)
        .then(registration => {
        })
        .catch(function(err) {
          console.log("Error", err);
        });
  }
}
