import React, { Component } from 'react'
// import QrReader from 'react-qr-reader'
import { Chip } from 'react-mdl';
import { Snackbar, Button } from 'react-mdl';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { BrowserMultiFormatReader } from '@zxing/library';
import {apiEndPoint} from "./App";

const codeReader = new BrowserMultiFormatReader();

export default class camera extends Component {
  onBack() {
    this.props.history.push({
      pathname: '/home',
      state: { some: 'state' }
    });
  }

  constructor(props) {
    super(props);
    this.handleShowSnackbar = this.handleShowSnackbar.bind(this);
    this.handleTimeoutSnackbar = this.handleTimeoutSnackbar.bind(this);
    this.onBack = this.onBack.bind(this);
    this.state = {
      isSnackbarActive: false,
      list: [],
      currentPart: ""
    }
    this.handleScan = this.handleScan.bind(this);
  }

  handleShowSnackbar() {
    this.setState({ isSnackbarActive: true });
  }
  handleTimeoutSnackbar() {
    this.setState({ isSnackbarActive: false });
  }

  componentWillUnmount() {
    this.allParts();
  }

  componentDidMount() {
    this.initializeScanner();
    //Fetch All Parts
    this.allParts();
    //If user added part in offline mode and now time to send back once online
    if (navigator.onLine && localStorage.getItem("addPartRequest") !== null) {
      var parts = JSON.parse(localStorage.getItem("addPartRequest"));
      localStorage.setItem("addPartRequest", JSON.stringify([]));

      if (parts.length > 0) {
        parts.forEach(PartID => {
          fetch(`${apiEndPoint}/api/TempQuoteItems/Put?PartID=${PartID.partId}&TpiTag=${PartID.tpiId}`, {
            method: 'put',
            mode: 'cors',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization-Token': JSON.parse(localStorage.getItem("LogInDetails"))
            }
          })
            .then(response => {
              if (response.status >= 400) {
                alert("Bad Response");
              }
              return response.json();
            })
            .then(response => {
              this.handleShowSnackbar();
            }).catch(function (error) {
              let getLatest = JSON.parse(localStorage.getItem("addPartRequest"));
              getLatest.push(PartID);
              localStorage.setItem("addPartRequest", JSON.stringify(getLatest));
            });
        });
      }

    }

    if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia) {
      navigator.mediaDevices.getUserMedia || navigator.getUserMedia(
        {
          video: true
        },
        function (localMediaStream) { },
        function (err) {
          alert('The following error occurred when trying to access the camera: ' + err);
        }
      );
    } else {
      alert("Camera not working try to open application in Browser");
    }
  };

  async initializeScanner() {

    let selectedDeviceId;
    codeReader.getVideoInputDevices()
      .then((videoInputDevices) => {
        selectedDeviceId = videoInputDevices[0].deviceId
      });

    codeReader.decodeFromVideoDevice(selectedDeviceId, 'video', (result, err) => {
      if (result) {
        this.handleScan(result.text)
      }
    })
  };


  //Fetch All Parts
  allParts = event => {
    fetch(`${apiEndPoint}/api/TempQuoteItems/Get`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization-Token': JSON.parse(localStorage.getItem("LogInDetails"))
      }
    })
      .then(response => {
        if (response.status >= 400) {
          console.log("Bad Response");
        }
        return response.json();
      })
      .then(response => {
        this.setState({
          // Filter and display data after filted, so we are saving just required data not unnecessary
          list: response.Data.filter(x => x.Quantity > 0)
        });
        if (localStorage.getItem("parts") !== null) {
          localStorage.removeItem("parts");
          localStorage.setItem("parts", JSON.stringify(response.Data.filter(x => x.Quantity > 0)));
        }
      }).catch((error) => {
        this.setState({
          list: JSON.parse(localStorage.getItem("parts")),
        });
      });
  };

  //When Camera Over the QR Code and catch data
  handleScan(data) {

    if (data) {
      // if (data.split(':')[0] === "i") {

      if (data) {

        let splitResult = "";
        let partnerTag = "";
        /* if it is TPI tag find by regular expression */
        if((/^i:[0-9]+\|p:([0-9]+\.?[0-9]*|null)\|s:.+$/i).test(data) || (/^i:[0-9]+[\>À]p:([0-9]+\.?[0-9]*|null)[\>À]s:.+$/i).test(data) || (/^iÑ[0-9]+[Ç\]]pÑ([0-9]+\.?[0-9]*|null)[Ç\]]sÑ.+$/i).test(data)) {
          splitResult = data.split('|');
        } else {
          partnerTag = data;
        }
        let finalId = splitResult.length > 0 ? splitResult[0].substring(splitResult[0].lastIndexOf(":") + 1) : partnerTag;
        if (this.state.currentPart !== finalId) {
          let scannedParts = JSON.parse(localStorage.getItem("addPartRequest"));
          let existingParts = JSON.parse(localStorage.getItem("parts"));
          let onlineList = this.state.list;
          let checkList = false;
          // If their is a already someparts either in the List of in the pending list
          if (scannedParts !== null || existingParts !== null || onlineList !== null) {

            if (scannedParts !== null) {
              scannedParts.forEach(part => {
                if (part.partId.toString() === finalId) {
                  checkList = true;
                }
              });
            }
            if (existingParts !== null) {
              existingParts.forEach(part => {

                if (part.PartID.toString() === finalId) {
                  checkList = true;
                }
                
                // If it is partner tag then check if their is a partner already with a partner tag
                if(part.Barcode !== null){
                  let partnerIdFromExistingPart = part.Barcode.substring(part.Barcode.lastIndexOf(':')+1);
                  if(partnerIdFromExistingPart.toString() === finalId.toString()){
                    checkList = true;
                  }
                }
              });
            }
            if (onlineList !== null) {
              onlineList.forEach(part => {
                if (part.PartID.toString() === finalId) {
                  checkList = true;
                }
              })
            }
          }
          // Part Already Exist in the list
          if (checkList === true) {
            alert("Part already exist in the list");
            checkList = false;
          }
          // Part Not Exist
          else if (checkList === false) {

            // If OffLine then store in Local-Storage
            if (!navigator.onLine) {
              // If Local Storage not Exist
              if (localStorage.getItem("addPartRequest") === null) {
                localStorage.setItem("addPartRequest", JSON.stringify([{ partId: finalId, tpiId: splitResult.length > 0 ? true : false }]));
                this.handleShowSnackbar();
                // Else if Local Storage Exist
              } else if (localStorage.getItem("addPartRequest") !== null) {
                let existingParts = JSON.parse(localStorage.getItem("addPartRequest"));
                existingParts.push({ partId: finalId, tpiId: splitResult.length > 0 ? true : false });
                localStorage.setItem("addPartRequest", JSON.stringify(existingParts));
                this.handleShowSnackbar();
              }
              // Else Online send to server
            } else {
              fetch(`${apiEndPoint}/api/TempQuoteItems/Put?PartID=${finalId}&TpiTag=${splitResult.length > 0 ? true : false}`, {
                method: 'put',
                mode: 'cors',
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization-Token': JSON.parse(localStorage.getItem("LogInDetails"))
                }
              })
                .then(response => {
                  if (response.status >= 400) {
                    console.log("Bad Response");
                  }
                  this.allParts();
                  return response.json();

                })
                .then(response => {
                  this.handleShowSnackbar();
                }).catch(function (error) {
                  // If Failed to save the part then keeps it in the storage
                  if (localStorage.getItem("addPartRequest") === null) {
                    localStorage.setItem("addPartRequest", JSON.stringify([{ partId: finalId, tpiId: splitResult.length > 0 ? true : false }]));
                  } else if (localStorage.getItem("addPartRequest") !== null) {
                    let existingParts = JSON.parse(localStorage.getItem("addPartRequest"));
                    existingParts.push({ partId: finalId, tpiId: splitResult.length > 0 ? true : false });
                    localStorage.setItem("addPartRequest", JSON.stringify(existingParts));
                  }
                  alert(finalId + " Added in the list and send back to server once you are online");
                });
            }
          }
          const initialState = { ...this.state };
          initialState.currentPart = finalId;
          this.setState(initialState);
        }
      }
    }

  }

  handleError(err) {
    console.error(err)
  }

  render() {
    const { isSnackbarActive } = this.state;
    return (
      <div>
        <div>
          <video
            id="video"
            width="100%"
            height="auto"
          ></video>
        </div>
        <Chip style={{ pointerEvents: "none" }}>Total Parts in List: {this.state.list.reduce((x, i) => x + i.Quantity, 0)}</Chip><br />
        <Chip style={{ pointerEvents: "none" }}>Face your device camera towards the QR Code</Chip><br /><br />
        <Button onClick={this.allParts} style={{ color: "#3a79b0", marginRight: "18px" }} >
          <i style={{ color: "#e82c1d" }} ><ArrowBackIos /></i><Link to="/home" style={{ color: "#3a79b0", textDecoration: "none" }}>Back to List</Link>
        </Button>
        <Snackbar active={isSnackbarActive} onTimeout={this.handleTimeoutSnackbar}>Part Added Successfully</Snackbar>
      </div>
    )
  }
}
