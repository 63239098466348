
import React, { Component } from 'react'
import { List, ListItem, ListItemContent, Grid, Cell, Button, Chip } from 'react-mdl';
import DeleteForever from "@material-ui/icons/DeleteForever";
import Cached from "@material-ui/icons/Cached";
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import {apiEndPoint} from "./App";

export default class home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      details: [],
      isLoaded: true,
      isLogin: true,
    };
  }
  componentWillUnmount() {
    this.allParts();
  }
  //Get List of all Parts on Page Load
  componentDidMount() {
    //If Offline and have data in localstorage
    if (!navigator.onLine && localStorage.getItem("parts") !== null) {
      this.setState({
        list: JSON.parse(localStorage.getItem("parts")),
        isLogin: true,
        isLoaded: true
      })
    }
    //If LoggedIn Details available in Localstorage
    else if (navigator.onLine && localStorage.getItem("LogInDetails") !== null) {
      //If LoggedIn and Online and have Offline Post Requests 
      if (navigator.onLine && localStorage.getItem("addPartRequest") !== null) {
        var parts = JSON.parse(localStorage.getItem("addPartRequest"));
        localStorage.setItem("addPartRequest", JSON.stringify([]));
       if(parts.length > 0){
         console.log("here")
        parts.forEach(part => {
          fetch(`${apiEndPoint}/api/TempQuoteItems/Put?PartID=${part.partId}&TpiTag=${part.tpiId}`, {
            method: 'put',
            mode: 'cors',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization-Token': JSON.parse(localStorage.getItem("LogInDetails"))
            }
          })
            .then(response => {
              if (response.status >= 400) {
                console.log("Bad Response");
              }
              return response.json();
            })
            .catch(function (error) {
              let getLatest = JSON.parse(localStorage.getItem("addPartRequest"));
              getLatest.push(part);
              localStorage.setItem("addPartRequest", JSON.stringify(getLatest));
            });
        })
       }
      }
      this.allParts();
      this.partDetails();
    }
    
      setInterval(() => {
        if(this.state.isLoaded === true && this.state.isLogin === true){
        this.allParts();
        this.partDetails();
      }
      }, 2500);
  
  }
  allParts = event => {
    fetch(`${apiEndPoint}/api/TempQuoteItems/Get`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization-Token': JSON.parse(localStorage.getItem("LogInDetails"))
      }
    })
      .then(response => {
        if (response.status >= 400) {
          console.log("Bad Response");
        }
        return response.json();
      })
      .then(response => {
        this.setState({
          // Filter and display data after filted, so we are saving just required data not unnecessary
          list: response.Data.filter(x => x.Quantity > 0),
          isLoaded: true,
          isLogin: true
        });
        if (localStorage.getItem("parts") === null) {
          localStorage.setItem("parts", JSON.stringify(response.Data.filter(x => x.Quantity > 0)))
        }
        if (localStorage.getItem("parts") !== null) {
          localStorage.removeItem("parts");
          localStorage.setItem("parts", JSON.stringify(response.Data.filter(x => x.Quantity > 0)))
        }
      }).catch((error) => {
        this.setState({
          list: JSON.parse(localStorage.getItem("parts")),
          isLoaded: true,
          isLogin: true
        });
      });
  }
  //Get Part Details
  partDetails = PartID => event => {
    fetch(`${apiEndPoint}/api/TempQuoteItems/Get?PartID=${PartID}`, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Access-Control-Request-Method': 'get',
        'Access-Control-Request-Headers': 'accept, x-my-custom-header',
        'Access-Control-Allow-Origin': '*',
        'Authorization-Token': JSON.parse(localStorage.getItem("LogInDetails"))
      }
    })
      .then(response => {
        if (response.status >= 400) {
          console.log("Bad Response");
        }
        return response.json();
      })
      .then(response => {
        // console.log(response.Data[0]);
        // console.log(PartID);
        this.setState({
          details: response.Data,
          isLoaded: false,
          isLogin: true
        })
      }).catch(function (error) {
      });
  };
  //Add Quantity
  addQty = PartID => event => {
    fetch(`${apiEndPoint}/api/TempQuoteItems/Put?PartID=${PartID}&TpiTag=true`, {
      method: 'put',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization-Token': JSON.parse(localStorage.getItem("LogInDetails"))
      }
    })
      .then(response => {
        if (response.status >= 400) {
          console.log("Bad Response");
        }
        return response.json();
      })
      .then(response => {
        // console.log(response);
        this.allParts();
      }).catch(function (error) {
      });
  }
  //Remove Quantity
  removeQty = PartID => event => {
    fetch(`${apiEndPoint}/api/TempQuoteItems/post?PartID=${PartID}`, {
      method: 'post',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization-Token': JSON.parse(localStorage.getItem("LogInDetails"))
      }
    })
      .then(response => {
        if (response.status >= 400) {
          console.log("Bad Response");
        }
        return response.json();
      }).then(response => {
        this.allParts();
      }).catch(function (error) {
      });
  };
  //Delete a Part
  deletePart = (PartID, Quantity) => () => {
    for (var i = 0; Quantity > i; i++) {
      fetch(`${apiEndPoint}/api/TempQuoteItems/Delete?PartID=${PartID}`, {
        method: 'post',
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Methods': 'Delete',
          'Access-Control-Request-Headers': 'accept, x-my-custom-header',
          'Access-Control-Allow-Origin': '*',
          'Authorization-Token': JSON.parse(localStorage.getItem("LogInDetails"))
        }
      })
        .then(response => {
          if (response.status >= 400) {
            console.log("Bad Response");
          }
          return response.json();
        }).then(response => {
          this.allParts();
        }).catch(function (error) {
        });
    }
  };
  //Delete a List of Parts (All Parts)
  deleteAll = () => {
    var check = window.confirm('Remove all parts from part list?');
    if(check === true){
    
    var parts = JSON.parse(localStorage.getItem("parts"));
    parts.forEach(x => {
      for (var i = 0; x.Quantity > i; i++) {
        fetch(`${apiEndPoint}/api/TempQuoteItems/Delete?PartID=${x.PartID}`, {
          method: 'post',
          mode: 'cors',
          headers: {
            'Access-Control-Allow-Methods': 'Delete',
            'Access-Control-Request-Headers': 'accept, x-my-custom-header',
            'Access-Control-Allow-Origin': '*',
            'Authorization-Token': JSON.parse(localStorage.getItem("LogInDetails"))
          }
        })
          .then(response => {
            if (response.status >= 400) {
              console.log("Bad Response");
            }
            return response.json();
          }).then(response => {
            this.allParts();
          }).catch(function (error) {
          });
      }
    })
  }
  };
  render() {
    if (this.state.list === null) {
      return (
        <div>
          <h4>Please Go-Online Now</h4>
        </div>
      );
    }
    else if (this.state.isLoaded === true && this.state.isLogin === true) {
      return (
        <div>
          <div style={{ marginBottom: "15px", background: "#444" }}>
            <Button onClick={this.deleteAll} style={{ color: "#fff" }} ><i style={{ color: "#e82c1d" }} ><DeleteForever /></i> Delete All</Button>
            <Button onClick={refreshPage} style={{ color: "#fff" }}><i style={{ color: "#e82c1d" }} ><Cached /></i> Refresh</Button>
            </div>
          
          <Chip style={{ pointerEvents: "none" }}>Total: ${this.state.list.reduce((x, i) => x + i.Price * i.Quantity, 0).toLocaleString()} | Parts: {this.state.list.reduce((x, i) => x + i.Quantity, 0)}</Chip>
          {this.state.list.map((x, i) => {
            return (
              <div key={i}>
                <List>
                  <ListItem twoLine style={{ height: "100px" }}>
                    <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }} >
                      <Button onClick={this.partDetails(x.PartID)} style={{ color: "#e82c1d" }} >
                        <ListItemContent style={{ fontSize: "21px", fontWeight: "bold" }} subtitle={"Price: " + "$" + x.Price.toLocaleString() + " | Quantity: " + x.Quantity} >{x.StockNumber}
                        </ListItemContent></Button> <br /> <br />
                      <div>
                        <Button onClick={this.addQty(x.PartID)}><i style={{ color: "#3a79b0" }} ><AddCircle /></i></Button>
                        <Button onClick={this.removeQty(x.PartID)}><i style={{ color: "#3a79b0" }} ><RemoveCircle /></i></Button>
                        <Button onClick={this.deletePart(x.PartID, x.Quantity)}><i style={{ color: "#3a79b0" }} ><DeleteForever /></i></Button>
                      </div>
                    </div>
                  </ListItem>
                </List>
                <hr />
              </div>
            )
          })}
        </div>
      );
    }
    else if (this.state.isLoaded === false && this.state.isLogin === true) {
      return (
        <div>
          {this.state.details.map(x => {
            if (x.Key === "Price") {
              return (
                <div style={{ width: '80%', margin: 'auto' }}>
                  <Grid className="demo-grid-ruler">
                    <Cell style={{ fontSize: "21px", fontWeight: "bold", color: "#333" }} col={x.Key}>{x.Key}</Cell>
                    <Cell col={x.Key}>{"$ " + x.Value.toLocaleString()}</Cell>
                  </Grid>
                </div>
              )
            } else {
              return (
                <div style={{ width: '80%', margin: 'auto' }}>
                  <Grid className="demo-grid-ruler">
                    <Cell style={{ fontSize: "21px", fontWeight: "bold", color: "#333" }} col={x.Key}>{x.Key}</Cell>
                    <Cell col={x.Key}>{x.Value}</Cell>
                  </Grid>
                </div>
              )
            }
          })}
          <Button onClick={this.allParts} style={{ color: "#3a79b0", marginRight: "18px" }} ><i style={{ color: "#e82c1d" }}><ArrowBackIos /></i>Back to List</Button>
        </div>
      );
    }
    else if (this.state.isLogin === false) {
      return (
        <div>
          <h4>Please Log In First</h4>
        </div>
      );
    }
  }
}

function refreshPage() {
  if (navigator.onLine) {
    window.location.reload();
  }
}

      // function createIndexedDB() {
      //   if(!('indexedDB' in window)) {return null;}
      //   return indexedDB.open('dashboardr', 1, function(upgradeDb){
      //     if(!upgradeDb.objectStoreNames.contains('events')){
      //       const eventOS = upgradeDb.createObjectStore('parts', {KeyPath: 'PartID'});
      //     }
      //   });
      // }
       // storedList();

//  //IndexedDB - Database
//  function storedList() {
//   var db;
//   var openRequest = indexedDB.open('Parts', 1);
//   openRequest.onupgradeneeded = function (event) {
//     var db = event.target.result;
//     console.log('running onupgradeneeded');
//     if (!db.objectStoreNames.contains('store')) {
//        db.createObjectStore('store',{ keyPath: 'PartID' });
//     }
//   };
//   openRequest.onsuccess = function (e) {
//     console.log('running onsuccess');
//     db = e.target.result;
//     addItem();
//   };
//   function addItem() {
//     var transaction = db.transaction(['store'], 'readwrite');
//     response.Data.forEach(item => {
//       var request = transaction.objectStore("store").add(item);
//       request.onsuccess = function (e) {
//         console.log('Success');
//       };
//     })
//   }
// };
